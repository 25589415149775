import React from "react";
import { Layout } from "components";
import { Hero, PostList } from "components/ACF";
import { PostPageByCategoryQuery } from "codegen";
import { PageProps, graphql } from "gatsby";

interface CategoryArchivePageContext {
  numberOfPages: number;
  previousPagePath: string;
  nextPagePath: string;
  currentPageNumber: number;
}

const CategoryArchiveTemplate = ({
  pageContext,
  data,
  location,
}: PageProps<PostPageByCategoryQuery, CategoryArchivePageContext>): JSX.Element => {
  const { numberOfPages, currentPageNumber } = pageContext;
  const { post, posts, categories, pageHero } = data;
  const { seo, name } = post;

  const allPosts = posts.nodes.map((node) => node);
  const allCategories = categories.nodes.map((node) => node);

  return (
    <Layout seo={seo}>
      <>
        <Hero data={pageHero?.acfLayout?.layout[0]} />
        <PostList
          data={{
            currentPageNumber,
            numberOfPages,
          }}
          postListItems={allPosts}
          postListCategories={allCategories}
          browserLocation={location}
        />
      </>
    </Layout>
  );
};

export default CategoryArchiveTemplate;

export const CategoryArchivePageQuery = graphql`
  query PostPageByCategory($id: String!, $offset: Int!, $postsPerPage: Int!) {
    post: wpCategory(id: { eq: $id }) {
      id
      slug
      uri
      name
      seo {
        title
        fullHead
        metaRobotsNofollow
        metaRobotsNoindex
        breadcrumbs {
          text
          url
        }
      }
      featuredImage {
        image {
          title
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED, width: 1800)
            }
          }
        }
      }
    }
    categories: allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        id
        name
        slug
        uri
      }
    }
    posts: allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        ...PostFields
      }
    }
    pageHero: wpPage(isPostsPage: { eq: true }) {
      acfLayout {
        layout {
          ...HeroQuery
        }
      }
    }
  }
`;
